import resume from '../resume.json';
import { Parallax } from 'react-parallax';

function Header() {
  let buttons = [];
  let certs = [];

  resume.basics.profiles.forEach((profile) => {
    buttons.push(
      <a href={profile.url} key={profile.url} target="_blank" rel="noreferrer" className="btn btn-primary btn-lg"><i className={profile.icon}></i><span>{profile.network}</span></a>
    );
  });

  resume.certificates.forEach((cert) => {
    certs.push(
      <div className="cert">
        <a href={cert.url} target="_blank" rel="noreferrer">
          <img src={cert.icon} alt={cert.name} />
        </a>
      </div>
    );
  });

  return (
    <Parallax
      bgImage="./background.jpg"
      bgImageAlt="background"
      strength={250}
    >
      <div className="jumbotron">
        <div className="container">
          <h1>{resume.basics.name}</h1>
          {/*
            <a href="/resume" target="_blank" className="btn btn-primary btn-lg"><i className="fa fa-file"></i><span>Resume (.pdf)</span></a>
          */}
          {/*
            <a href="/cv" target="_blank" className="btn btn-primary btn-lg"><i className="fa fa-file"></i><span>CV (.pdf)</span></a>
          */}
          {buttons}
          <p>{resume.basics.summary}</p>
          <div className="certs">
            {certs}
          </div>
          <p className="details"><i className="fa fa-map-marker"></i>&nbsp; {resume.basics.location.city}, {resume.basics.location.region} &nbsp; &mdash; &nbsp; <i className="fa fa-envelope"></i>&nbsp; {resume.basics.email}</p>
        </div>
      </div>
    </Parallax>
  )
}

export default Header;
