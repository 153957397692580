import resume from '../resume.json';

function Footer() {
  return (
    <div className="section footer">
      <div className="container">
        <p>Copyright &copy;{new Date().getFullYear()} Vivek Nair. All Rights Reserved. Last updated {resume.meta.lastModified}.{/* <a href={resume.meta.canonical}>View as JSON</a>.*/}</p>
      </div>
    </div>
  )
}

export default Footer;
