import research from '../data/preprints.json';

function Research() {
  let publications = [];

  research.forEach((publication) => {
    let authors = []

    for (let author of publication.authors) {
      if (author.includes("Vivek Nair")) authors.push(<b>{author}</b>)
      else authors.push(author)
    }

    if (authors.length === 2) {
      authors = <>{authors[0]} and {authors[1]}</>
    } else {
      let out = []
      for (let i = 0; i < authors.length - 1; i++) {
        out.push(authors[i])
        out.push(", ")
      }
      out.push("and ")
      out.push(authors[authors.length - 1])
      authors = out
    }

    publications.push(
      <p key={publication.link} className="my-3">
        <a style={{fontWeight: 'normal'}} target="_blank" rel="noreferrer" href={publication.link}>&ldquo;{publication.title},&rdquo;</a><br/> {authors}
      </p>
    );
  })

  return (
    <div className="section research preprints">
      <div className="container">
        <h2>Preprints</h2>
        {publications}
      </div>
    </div>
  )
}

export default Research;
