import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Header from './components/Header';
// import Patents from './components/Patents';
import Research from './components/Research';
import Preprints from './components/Preprints';
// import Press from './components/Press';
// import Videos from './components/Videos';
import Footer from './components/Footer';
// import Posters from './components/Posters';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/">
          <Header />
          { /* <Press /> */ }
          <Research />
          <Preprints />
          { /* <Patents /> */ }
          { /* <Posters /> */ }
          { /* <Videos /> */ }
          <Footer />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

function NotFound() {
  return (
    <div className="not-found">
      <div className="message">
        <h1>404</h1>
        <p>The page you were looking for doesn't appear to exist.</p>
        <a href="/">Go back to home page</a>
      </div>
    </div>
  );
}

reportWebVitals();
